import React from 'react';
import { graphql } from 'gatsby';
import {
   ImageText,
   Layout,
   PageHeader,
   Seo,
   PageSubtitle,
   CallToAction,
} from '../../components';

const DispatchServices = ({ data }) => {
   const {
      wserviceSubtitle,
      wserviceListItem,
      wwofferImage,
      wwofferTitle,
      wwofferDescription,
      wwofferListItem,
      wywneedImage,
      wywneedTitle,
      wywneedDescription,
      wywneedListItem,
      requestDispatch,
   } = data.datoCmsDispatchService;

   return (
      <Layout>
         <Seo
            title='Dispatch Services'
            description='We work together as a team.  We guarantee negotiating top paying rates for you and you decide  where to be dispatched.  Its that’s simple!'
         />
         <PageHeader
            title='Dispatch Services'
            breadCrumbs='services'
            bgImg={data.titleImage.childImageSharp.gatsbyImageData}
         />
         <PageSubtitle subtitle={wserviceSubtitle} list={wserviceListItem} />
         <ImageText
            altDir='true'
            img={wwofferImage}
            title={wwofferTitle}
            description={wwofferDescription}
            list={wwofferListItem}
         />
         <ImageText
            img={wywneedImage}
            title={wywneedTitle}
            description={wywneedDescription}
            list={wywneedListItem}
         />
         {/* <DispatchForm /> */}
         <CallToAction
            ctaTitle=''
            description={requestDispatch}
            // address='/dispatch-quote'
            linkAddress='https://docs.google.com/forms/d/e/1FAIpQLSeSQHyXC_nK06VK3V_d_DgLTZb2xvc3bz-U-qhp7dAkjaKaUA/viewform'
            btnText='Request Dispatch Service'
            dark='true'
         />
      </Layout>
   );
};

export const query = graphql`
   {
      titleImage: file(name: { eq: "dispatch-header" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
         }
      }
      datoCmsDispatchService {
         wserviceSubtitle
         wserviceListItem {
            id
            listItem
         }
         wwofferImage {
            gatsbyImageData(layout: FULL_WIDTH)
         }
         wwofferTitle
         wwofferDescription
         wwofferListItem {
            id
            listItem
         }
         wywneedImage {
            gatsbyImageData(layout: FULL_WIDTH)
         }
         wywneedTitle
         wywneedDescription
         wywneedListItem {
            id
            listItem
         }
         requestDispatch
      }
   }
`;

export default DispatchServices;
